@import 'styles/colours';

.split-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 150px;
  padding: 0 40px;

  &__column {
    width: 50%;
    position: relative;

    &--content {
      display: flex;
      justify-content: center;
    }
  }

  &__content {
    max-width: 450px;
  }

  &__title {
    margin-bottom: 20px;

    span, strong {
      color: $primary-orange;
    }
  }

  &__button {
    display: inline-block;
    margin-top: 40px;
  }

  &__background {
    position: absolute;
    width: 70vw;
    height: 800px;
    right: -40px;
    top: -100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    z-index: -1;
  }

  &__image {
    width: 140%;
  }
  
  &--left-justified {
    flex-direction: row-reverse;

    .split-section__background {
      left: unset;
    }

    .split-section__column--content {
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .split-section {
    &__title {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .split-section {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 100px;

    &__column {
      width: 100%;
      
      &--content {
        justify-content: flex-start;
      }
    }

    &__title {
      font-size: 28px;
    }

    &__image {
      width: 100%;
      max-width: 450px;
    }

    &__background {
      width: calc(100vw - 40px);
      height: 300px;
      max-width: 600px;
      top: unset;
      left: 0;
      right: unset;
      bottom: 50px;
      background-color: #F2EFEA;
      border-radius: 0;
      border-top-left-radius: 500px;
      background-image: none !important;
    }

    &:not(.split-section--left-justified) {
      .split-section__column:first-of-type {
        display: flex;
        justify-content: flex-end;
      }
    }

    &--left-justified {
      .split-section__background {
        border-radius: 0;
        border-top-right-radius: 500px;
      }

      .split-section__column--content {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}