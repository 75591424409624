.navigation-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 0 40px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  background: #FFF;
  z-index: 99;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  &__logo {
    width: 180px;
  }

  &__menu {
    ul {
      display: flex;
      align-items: center;
    }

    li {
      padding: 0 15px;

      a {
        font-family: "IsidoraSans-Bold", sans-serif;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
  
  &__hamburger-menu {
    display: none;
    align-items: center;
    width: 25px;
    height: 20px;
    position: relative;
    padding: 0;
    margin: 0;
    background: none;
    border: none;

    &--active {
      .navigation-header__menu-bar {
        transform: rotate(45deg);

        &::before {
          top: 0;
        }

        &::after {
          top: 0;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__menu-bar {
    width: 100%;
    height: 2px;
    position: relative;
    background-color: #000;
    transition: 0.3s;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      background-color: #000;
      transition: 0.3s;
    }

    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
    }
  }
}

.mobile-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: 100;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: 0.3s;
  }

  &__inner {
    position: absolute;
    right: -100%;
    width: 85vw;
    height: 100vh;
    max-width: 350px;
    padding: 0 5px;
    background: #FFF;
    transition: 0.3s;
  }

  &--active {
    visibility: visible;
  
    .mobile-menu__overlay {
      opacity: 0.7;
    }

    .mobile-menu__inner {
      right: 0;
    }
  }

  &__close-button {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 20px;
    right: 30px;
    background: none;
    border: none;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      background-color: #000;
      transition: 0.3s;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__navigation {
    margin-top: 50px;

    li {
      padding: 20px 25px;
      border-bottom: 1px solid #00000040;

      a {
        font-family: "IsidoraSans-Bold", sans-serif;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .navigation-header {
    &__menu {
      display: none;
    }

    &__hamburger-menu {
      display: flex;
    }
  }
}