$button-color: #0D3B66;
$primary-blue: #0A014F;
$secondary-blue: #0D3B66;
$primary-orange: #FA8722;

$color-primary: #0D3B66;
$color-primary-highlight: #343944;
$color-primary-dark: #0D0630;
$color-text-light: #FFFFFF;
$color-text: #000000;
$color-background: #E9EDF1;
$color-error: red;
$color-disabled: rgba(0, 0, 0, 0.1);

// Font-colors
$color-font-disabled: #BABEC1;

// Palette colours
$color-sundance-yellow: #FFDA9F;
$color-junglemist-blue: #AECDD5;