@import 'styles/colours';

.feature-section {
  margin: 100px 0;
  padding: 0 40px;

  &__inner {
    margin-bottom: 40px;
  }

  &__title {
    max-width: 700px;
    margin-bottom: 20px;

    span, strong {
      color: $color-primary;
    }
  }

  &__description {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .feature-section {
    margin: 40px 0;
    padding: 0 20px;
  }
}