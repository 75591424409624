@import 'styles/colours.scss';
@import 'styles/sizes.scss';
@import 'styles/fonts.scss';

.button {
  padding: 11px 45px 14px 45px;
  border-radius: 50px;
  background: none;
  border: 4px solid;
  cursor: pointer;

  font-size: 16px;
  font-family: $font-alt;
  transition: 0.3s;

  &--link {
    box-sizing: border-box;
    font-size: 16px;
    font-family: $font-alt;
    text-decoration: none;
  }

  &--primary {
    background-color: $color-primary;
    color: $color-text-light;
    border-color: $color-primary;

    &:hover {
      background-color: $primary-orange;
      border-color: $primary-orange;
    }
  }

  &--secondary {
    color: $color-primary;
    background: none;
    border-color: $color-primary;
  }

  &--disabled {
    background-color: $color-background;
    border-color: $color-background;
    color: $color-font-disabled;
    cursor: not-allowed;

    &:hover {
      background-color: $color-background;
      border-color: $color-background;
    }
  }

  &--ghost {
    background-color: #f1f5f8;
    color: initial;
    border-color: #f1f5f8;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
      color: #FFF;
    }
  }

  svg {
    position: relative;
    top: 2px;
    margin-left: 20px;
  }
}